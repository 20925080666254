/* eslint-disable no-unused-vars */
import React, { lazy, Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import ContentLoader from "../components/ui/ContentLoader";
import history from "../helpers/history";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

const Routes = (props) => {
  const Login = lazy(() => import("../pages/auth/Login"));
  const Home = lazy(() => import("../pages/Home"));
  const Activity = lazy(() => import("../pages/Activity"));
  const Account = lazy(() => import("../pages/Account"));
  const Dashboard = lazy(() => import("../pages/Dashboard"));
  const Community = lazy(() => import("../pages/Community"));
  const LoadBooking = lazy(() => import("../pages/LoadBooking"));
  const PageNotFound = lazy(() => import("../pages/PageNotFound"));

  return (
    <>
    <BrowserRouter history={history}>
      <>
        <ToastContainer
          autoClose={5000}
          transition={Zoom}
          toastClassName="dark-toast"
        />
        <Suspense fallback={
          <div className="w-full h-full items-center flex justify-center">
            <ContentLoader type="Puff" color="#ccc" h="45" w="45"/>
          </div>
        }>
          <Switch>
            {/* public route: accessible to none authenticated users */}
            <PublicRoute exact path="/" component={Login} />
            <PrivateRoute exact path="/home" component={Home}/>
            <PrivateRoute exact path="/activity" component={Activity}/>
            <PrivateRoute exact path="/account" component={Account}/>
            <PrivateRoute exact path="/dashboard" component={Dashboard}/>
            <PrivateRoute exact path="/community" component={Community}/>
            <Route
              exact 
              path="/load-booking/:id" 
              component={LoadBooking}
            />
            <Route path="/404" component={PageNotFound} />
            <Redirect to="/404" />
            {/* <PublicRoute
              exact
              path="/create-password/:token/:id"
              component={CreatePassword}
            />
            <PublicRoute
              exact
              path="/request-access"
              component={RequestAccess}
            /> */}

            {/* private route: accessible to only authenticated users */}

            {/* <PrivateRoute exact path="/overview" component={Dashboard} /> */}
          </Switch>
        </Suspense>
      </>
    </BrowserRouter>
    </>
  );
};

export default Routes;