import { GET_THREADS,
    GET_MY_THREADS,
    GET_COMMENTS,
    GET_REPLIES,
    GET_MENTIONS,
    UPDATE_THREAD,
    UPDATE_COMMENT,
    CREATE_COMMENT,
    CREATE_THREAD,
    GET_THREAD_TOP_CONTRIBUTORS,
    GET_TOP_CONTRIBUTORS,
    GET_SORTED_THREADS,
    GET_TRENDING,
    GET_TOP_THREADS_BY_CHANNEL,
    SEARCH_THREADS,
    ADD_VIEW,
    GET_USER_THREAD_LIKES,
    GET_USER_COMMENT_LIKES,
    GET_USER_COMMENT_REPLIES,
    ADD_FLAG,
    UPDATE_THREAD_STATUS
} from "../types/threadTypes";
import { LOGOUT_USER } from "../types/authTypes";

const INITIAL_STATE = {
    threads: [],
    my_threads: [],
    comments: {},
    top_contributors: {},
    top_threads: [],
    trending: {},
    top_channel_threads: {},
    searched: [],
    my_likes: { threads: [], comments: [] },
    my_replies: [],
    my_mentions: [],
    total_contributions : {}
};

const updateItems = (existingItems, newItems, key, isCreation = false) => {
  const updatedItems = existingItems?.map(existingItem => {
      const matchingItem = newItems?.find(newItem => newItem[key] === existingItem[key]);
      return matchingItem ? matchingItem : existingItem;
  });

  const newItemsToAdd = newItems?.filter(newItem => !existingItems?.some(existingItem => existingItem[key] === newItem[key]));

  const allItems = [...updatedItems, ...newItemsToAdd];

  const allItemsAfterCreation = [...newItemsToAdd, ...updatedItems];

  return isCreation ? allItemsAfterCreation : allItems;
};

const updateThreadStatus = (threads, threadId, status) => {
  return threads.map(thread => {
      if (thread.thread_id === threadId) {
          if (status === 'OPEN') {
              return { ...thread, status: 'OPEN' };
          } else if (status === 'CLOSE') {
              return { ...thread, status: 'CLOSED' };
          } else if (status === 'DELETE') {
              return { ...thread, status: 'CLOSED', is_deleted: true };
          }
      }
      return thread;
  });
};

const threadsReducer = (state = INITIAL_STATE, action) => {

  let existingComments = state.comments[action?.payload?.threadId] || [];
  let threadId, updatedThreads, comments, replies, newComments, updatedComments, comment, topContributors;

  switch (action.type) {
    case GET_THREADS:
      return {
        ...state,
        threads: updateItems(state.threads, action.payload, 'thread_id'),
      };

    case GET_SORTED_THREADS:
      return {
        ...state,
        top_threads: action.payload,
      };

    case SEARCH_THREADS:
      return {
        ...state,
        searched: action.payload,
      };

    case GET_TOP_THREADS_BY_CHANNEL:
      return {
        ...state,
        top_channel_threads: {
            ...state.top_channel_threads,
            [action.payload.channelId] : action.payload.data
        },
      };

    case GET_MY_THREADS:
      return {
        ...state,
        my_threads: updateItems(state.my_threads, action.payload, 'thread_id'),
      };

    case GET_COMMENTS:

      threadId = action.payload.threadId;
      comments = action.payload.comments;

      // Update existing comments with the same comment_id
      updatedComments = existingComments.map(existingComment => {
        const matchingComment = comments.find(newComment => newComment.comment_id === existingComment.comment_id);
        return matchingComment ? matchingComment : existingComment;
      });

      // Add new comments with unique comment_id values
      newComments = comments.filter(newComment => !existingComments.some(existingComment => existingComment.comment_id === newComment.comment_id));

      return {
        ...state,
        comments: {
          ...state.comments,
          [threadId]: [...updatedComments, ...newComments],
        },
      };

    case GET_REPLIES:

      threadId = action.payload.threadId;
      replies = action.payload.replies;

      // Update existing comments with the same comment_id
      updatedComments = existingComments.map(existingComment => {
        const matchingComment = replies.find(newComment => newComment.comment_id === existingComment.comment_id);
        return matchingComment ? matchingComment : existingComment;
      });

      // Add new comments with unique comment_id values
      newComments = replies.filter(newComment => !existingComments.some(existingComment => existingComment.comment_id === newComment.comment_id));

      return {
        ...state,
        comments: {
          ...state.comments,
          [threadId]: [...updatedComments, ...newComments],
        },
      };

      case UPDATE_THREAD: {
        const updatedThreads = updateItems(state.threads, [action.payload], 'thread_id');
        const updatedThreads2 = updateItems(state.my_threads, [action.payload], 'thread_id');
        const updatedThreads3 = updateItems(state.searched, [action.payload], 'thread_id');
        const updatedThreads4 = updateItems(state.top_threads, [action.payload], 'thread_id');
        // const topChannelThreadsUpdate = action.payload?.channel_id ? {
        //   ...state.top_channel_threads,
        //   [action.payload?.channel_id]: 
        //     updateItems(
        //         action.payload?.channel_id ? state.top_channel_threads[action?.payload?.channel_id] || [] : [],
        //         action.payload,
        //         'thread_id'
        //     )
        // } : state.top_channel_threads;

        return {
            ...state,
            threads: updatedThreads,
            my_threads: updatedThreads2,
            searched: updatedThreads3,
            top_threads: updatedThreads4,
            //top_channel_threads: topChannelThreadsUpdate,
        };
    }
    
    case UPDATE_THREAD_STATUS: {
        
      const topChannelThreads = action.payload?.channel_id ? {
          ...state.top_channel_threads,
          [action.payload.channel_id]: 
            updateThreadStatus(
                action.payload?.channel_id ? state.top_channel_threads[action?.payload?.channel_id] || [] : [],
                action.payload.thread_id,
                action.payload.status
            )
        } : state.top_channel_threads;

        return {
            ...state,
            threads: updateThreadStatus(state.threads, action.payload.thread_id, action.payload.status),
            my_threads: updateThreadStatus(state.my_threads, action.payload.thread_id, action.payload.status),
            searched: updateThreadStatus(state.searched, action.payload.thread_id, action.payload.status),
            top_threads: updateThreadStatus(state.top_threads, action.payload.thread_id, action.payload.status),
            top_channel_threads: topChannelThreads,
        };
    }    

    case UPDATE_COMMENT:
      comment = action.payload;
      const updatedComment = comment;
      const updatedThreadId = comment.thread_id;
      const existingCommentsUpdate = state.comments[updatedThreadId] || [];

      // Update existing comments with the same comment_id
      const updatedCommentsUpdate = existingCommentsUpdate.map(existingComment => {
        return existingComment.comment_id === updatedComment.comment_id ? updatedComment : existingComment;
      });

      // Add the updated comment if it doesn't exist in the current state
      const newCommentsUpdate = existingCommentsUpdate.some(existingComment => existingComment.comment_id === updatedComment.comment_id)
        ? []
        : [updatedComment];

      return {
        ...state,
        comments: {
          ...state.comments,
          [updatedThreadId]: [...updatedCommentsUpdate, ...newCommentsUpdate],
        },
      };

    case CREATE_COMMENT:
      comment = action.payload.comment;
      threadId = comment.thread_id;
      existingComments = state.comments[threadId] || [];

      return {
        ...state,
        comments: {
          ...state.comments,
          [threadId]: [...existingComments, comment],
        },
      };

    case CREATE_THREAD:

      return {
        ...state,
        threads: updateItems(state.threads, [ action.payload ], 'thread_id', true),
        my_threads: updateItems(state.my_threads, [ action.payload ], 'thread_id', true),
      };

    case GET_THREAD_TOP_CONTRIBUTORS:
      topContributors = action.payload.data;
      threadId = action.payload.threadId;

      return {
        ...state,
        top_contributors: {
          ...state.top_contributors,
          [threadId]: topContributors,
        },
      };

      case GET_TOP_CONTRIBUTORS:
      topContributors = action.payload.data.topContributors;
      const timePeriod = action.payload.timePeriod
      const totalContributions = action.payload.data.totalContributions;

      return {
        ...state,
        top_contributors: {
          ...state.top_contributors,
          [timePeriod]: topContributors,
        },
        total_contributions: {
          ...state.total_contributions,
          [timePeriod]: totalContributions
        }
      };

    case GET_TRENDING:
      return {
          ...state,
          trending: {
            [action.payload.timePeriod]: action.payload.data,
          },
      };

    case ADD_VIEW:
      threadId = action.payload.threadId;
      const { views_count, unique_views_count } = action.payload;
      updatedThreads = state.threads.map((thread) =>
        thread.thread_id === threadId
          ? { ...thread, views_count, unique_views_count }
          : thread
      );

      const updatedMyThreads = state.my_threads.map((myThread) =>
        myThread.thread_id === threadId
          ? { ...myThread, views_count, unique_views_count }
          : myThread
      );

      const updatedSearchedThreads = state.searched.map((searchedThread) =>
        searchedThread.thread_id === threadId
          ? { ...searchedThread, views_count, unique_views_count }
          : searchedThread
      );

      return {
        ...state,
        threads: updatedThreads,
        my_threads: updatedMyThreads,
        searched: updatedSearchedThreads,
      };
    
    case GET_USER_THREAD_LIKES:
      return {
        ...state,
        my_likes: {
          ...state.my_likes,
          threads: [ ...new Set([...state.my_likes.threads, ...action.payload])],
        },
      };
    
    case GET_MENTIONS:
        // Create a new set to store unique mentions
        const uniqueMentions = new Set(state.my_mentions.map(mention => mention.mention_id));
      
        // Filter out any existing mentions with the same mention_id
        const filteredMentions = state.my_mentions.filter(mention => !uniqueMentions.has(mention.mention_id));
      
        // Merge the filtered mentions with the new mentions
        return {
          ...state,
          my_mentions: [...filteredMentions, ...action.payload],
        };
      

    case GET_USER_COMMENT_LIKES:
      return {
        ...state,
        my_likes: {
          ...state.my_likes,
          comments: [ ...new Set([...state.my_likes.comments, ...action.payload])],
        },
      };

    case GET_USER_COMMENT_REPLIES:
      const uniqueComments = action.payload.filter(newComment => (
        !state.my_replies.some(existingComment => existingComment.comment_id === newComment.comment_id)
      ));
    
      return {
        ...state,
        my_replies: [...state.my_replies, ...uniqueComments],
      };
    
    case ADD_FLAG:
      
      const { commentId, hasFlagged } = action.payload;
      threadId = action.payload?.threadId;

      // Handle the case for flagging a thread
      if (threadId) {
        const updatedThreads = state.threads.map((thread) =>
          thread.thread_id === threadId ? { ...thread, hasFlagged } : thread
        );

        const updatedMyThreads = state.my_threads.map((myThread) =>
          myThread.thread_id === threadId ? { ...myThread, hasFlagged } : myThread
        );

        const updatedSearchedThreads = state.searched.map((searchedThread) =>
          searchedThread.thread_id === threadId ? { ...searchedThread, hasFlagged } : searchedThread
        );

        return {
          ...state,
          threads: updatedThreads,
          my_threads: updatedMyThreads,
          searched: updatedSearchedThreads,
        };
      }

      // Handle the case for flagging a comment
      if (commentId) {
        // You need to adapt this part based on how your state is structured for comments
        const updatedComments = {
          ...state.comments,
          [commentId]: {
            ...state.comments[commentId],
            hasFlagged,
          },
        };

        const updatedMyReplies = state.my_replies.map((myReply) =>
          myReply.comment_id === commentId ? { ...myReply, hasFlagged } : myReply
        );

        return {
          ...state,
          comments: updatedComments,
          my_replies: updatedMyReplies,
        };
      }

      return state;

    case LOGOUT_USER:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default threadsReducer;

